/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.31/esri/copyright.txt for details.
*/
function t(t, r, f, n, h) {
  o(t, r, f || 0, n || t.length - 1, h || a);
}
function o(t, a, f, n, h) {
  for (; n > f;) {
    if (n - f > 600) {
      var M = n - f + 1,
        i = a - f + 1,
        u = Math.log(M),
        c = .5 * Math.exp(2 * u / 3),
        e = .5 * Math.sqrt(u * c * (M - c) / M) * (i - M / 2 < 0 ? -1 : 1);
      o(t, a, Math.max(f, Math.floor(a - i * c / M + e)), Math.min(n, Math.floor(a + (M - i) * c / M + e)), h);
    }
    var l = t[a],
      v = f,
      x = n;
    for (r(t, f, a), h(t[n], l) > 0 && r(t, f, n); v < x;) {
      for (r(t, v, x), v++, x--; h(t[v], l) < 0;) v++;
      for (; h(t[x], l) > 0;) x--;
    }
    0 === h(t[f], l) ? r(t, f, x) : r(t, ++x, n), x <= a && (f = x + 1), a <= x && (n = x - 1);
  }
}
function r(t, o, r) {
  var a = t[o];
  t[o] = t[r], t[r] = a;
}
function a(t, o) {
  return t < o ? -1 : t > o ? 1 : 0;
}
export { t as q };